import React, { Fragment, useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../context/SiteContext';
import { UserContext } from '../../context/UserContext';
import { WorkerContext } from '../../context/WorkerContext';

import dayjs from 'dayjs'
import byType    from '../../utils/reports/sorting/type';
import getValues from '../../utils/reports/values/values';

// import qrReport from '../../utils/reports/qrcodes';
import { QrCode } from 'react-bootstrap-icons';


// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { config } = useContext(SiteContext);
  const { schemas, allGroups } = useContext(UserContext);
  const { startWorker } = useContext(WorkerContext);
  const { typeId, typeVarId, project, phase, planGroups, plans, types, typeVars, plots, plot } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';

  const [logos, setLogos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    let filtered = schemas.filter(x => x.type==='qr-report' && x.status==='A');
    setPdfs(filtered);
  }, [schemas])

  useEffect(() => {
    let logos = allGroups.filter(x => 'logoBw' in x.config)
      .map(x => ({ name: x.label, url: x.config.logoBw}))
      .sort((a, b)=> (a.label.localeCompare(b.label, 'en', { numeric: true })));
    if(logos?.length > 0) setLogos(logos);
    else console.log('No logos available');
  }, [allGroups])

  const download = async (logoUrl, size) => {
    console.clear();
    setLoading(true);

    let arr = [];
    if(plots) arr = [...plots];
    else if (plot) arr = [plot];

    if(typeId) arr = arr.filter(x => x.typeId === typeId);
    if(typeVarId) arr = arr.filter(x => x.typeVarId === typeVarId);

    arr = await byType({ types, planGroups, plans, plots: arr });

    let sources = [];

    if(size==='1x1') {
      sources = [
        { field: 'name', parent: 'plan' },
        { field: 'textLabel', parent: 'plot.display' },
        { type: 'quick url' }
      ]
    } else {
      sources = [
        { field: 'name', parent: 'project' },
        { field: 'name', parent: 'phase' },
        { field: 'name', parent: 'plan' },
        { field: 'typeCode', parent: 'plot.display' },
        { field: 'name', parent: 'plot' },
        { field: 'arr1', parent: 'plot' },
        { type: 'quick url' }
      ]
    }

    let values = await getValues({ sources, arr, project, phase, types, typeVars });

    let pdf = {};
    if(size==='1x1') {
      pdf = {
        pageSize: { width: 72, height: 72 },
        pageMargins: [0,0,0,0],
        defaultStyle: { fontSize: 5, lineHeight: 0.9 },
        content: []
      }
  
      for(const val of values) {  
        pdf.content.push({
          pageBreak: 'after',
          layout: 'noBorders',
          table: {
            margin: [4,2],
            widths: [72],
            heights: [72],
            body: [
              [{ qr: val[2], fit: 80, margin: 1, alignment: 'center' }],
              // [{ text: `${val[0]} - ${val[1]}`, margin: 0 }],
            ]
          }
        })
      }

    } else {
      pdf = {
        pageSize: { width: 198, height: 153 },
        pageMargins: [10, 8, 10, 8],
        defaultStyle: { fontSize: 9, lineHeight: 0.9 },
        styles: {
          small: { fontSize: 7 },
          bold: { bold: true }
        },
        content: [],
        images: {
          logo: logoUrl
        }
      }
  
      for(const val of values) {
        let message = '';
        if(val[5]) {
          message = { stack: [
            { text: 'MESSAGE', style: ['small','bold'], margin: [0, 4, 0, 0] },
            { text: val[5].slice(0, 60) }
          ]}
        }
  
        pdf.content.push({
          layout: 'noBorders',
          table: {
            pageBreak: 'after',
            widths: [93,2,78],
            heights: [135],
            body: [
              [
                [
                  { text: val[0] },
                  { text: val[1] },
                  {
                    layout: 'noBorders',
                    margin: [0, 4, 0, 0],
                    table: {
                      widths: ['auto','auto'],
                      body: [
                        [
                          [
                            { text: 'FLOOR', style: ['small','bold'] },
                            val[2]
                          ],
                          [
                            { text: 'TYPE', style: ['small','bold'] },
                            val[3]
                          ]
                        ]
                      ]
                    }
                  },
                  { text: 'LOCATION', style: ['small','bold'], margin: [0, 4, 0, 0] },
                  val[4],
                  message
                ],
                '',
                [
                  { qr: val[6], fit: 75, margin: [0, 0, 0, 12] },
                  { image: 'logo', fit: [68, 45] }
                ]
              ]
            ]
          }
        })
      }
    }

    let timestamp = dayjs().format('YYYY-MM-DD');
    let name = `${project.name} - ${phase.name} - QR Code (PDF)`;
    let filename = `${project.name} - ${phase.name} - Qr Code ${timestamp}`;

    startWorker({ type: 'pdf', pieces: { name, filename, content: pdf } });
    setLoading(false);
  }

  if(!config.showQrCodes || logos?.length===0 || plots.length===0) return null;

  return (
    <div className={`d-inline-block dropdown ${wrapper}`}>
      <button type="button" className={classes} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
        { !loading && (
          <Fragment>
            <QrCode size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Generating
          </Fragment>
        )}   
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        { logos.map((obj, idx) => (
          <Fragment key={`${typeId}-${typeVarId}-${idx}`}>
            <li className="dropdown-item pointer" onClick={() => download(obj.url, '3x2')}>{obj.name} 3" x 2"</li>
          </Fragment>
        ))}
        <li className="dropdown-item pointer" onClick={() => download(null, '1x1')}>1" x 1"</li>
      </ul>
    </div>
  )
}

export default View;
