import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs'

import DownloadQr from '../../../components/buttons/DownloadQr';

import { UserContext } from '../../../context/UserContext';
import { WorkerContext } from '../../../context/WorkerContext';
import qtyReport from '../../../utils/reports/quantities';

// lklklk clean up this and the activeQty function so we are pulling the labels

// lklklk also need to update activeQty so it splits out by installMode instead
// of just blank/whatever qties

const View = () => {
  const { loadingPlots, project, types, typeVars, planGroups, phases, plans, activePhase, activePlots, activeQtys } = useOutletContext();
  const { userDetails } = useContext(UserContext);
  const { startWorker } = useContext(WorkerContext);

  const download = async (e) => {
    e.target.blur();
    let content = await qtyReport({ activeQtys });
    let timestamp = dayjs().format('YYYY-MM-DD');
    let name = `${project.name} - ${activePhase.name} Quantities (PDF)`;
    let filename = `${project.name} ${activePhase.name} Quantities ${timestamp}`;
    startWorker({ type: 'pdf', pieces: { name, filename, content } });
  }

  return (
    <div className="row">
      <div className="col-sm-9">
        { loadingPlots && (
          <div className="d-flex justify-content-center text-muted mt-3 p-3">
            <div className="spinner-border spinner-border-sm text-muted me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Loading plots...
          </div>
        )}

        { !loadingPlots && activeQtys.length===0 && (
          <div className="mb-3 p-2 bg-white rounded">
            <p className="p-3 mb-0">No plots yet. Add some or adjust filters.</p>
          </div>
        )}

        <table className="table border bg-white">
          <tbody>
            { activeQtys.map((obj) => {
              if(obj.typeId === 'total') {
                return (
                  <tr key={'qty-'+obj.typeId+'-'+obj.variationId}>
                    <td colSpan="2" align="right">Total</td>
                    <td colSpan="1" align="right">{obj.quantity}</td>
                    <td></td>
                  </tr>
                )
              }

              return (
                <tr key={'qty-'+obj.typeId+'-'+obj.variationId}>
                  <td valign="middle" width="22" title={userDetails.supe==='Y' ? obj.typeId : null}>
                    <div className="ms-2" style={{width: 20, height: 20, backgroundColor: obj.fillColor, border: '1px solid #666'}}></div>
                  </td>
                  <td valign="middle">
                    {obj.code} { obj.description ? ` - ${obj.description}` : ''}
                  </td>
                  <td valign="middle" align="right">{obj.quantity}</td>
                  <td valign="middle" width="60" align="right">
                    {obj.quantity > 0 && (
                      <DownloadQr typeId={obj.typeId} typeVarId={obj.variationId} project={project} planGroups={planGroups} plans={plans} phases={phases} phase={activePhase} types={types} typeVars={typeVars} plots={activePlots} activeQtys={activeQtys} wrapper="ms-2" classes="btn border" />
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="col-sm-3">
        <button className="btn btn-outline-dark shadow-sm me-2" onClick={download}>Download Report</button>
        <DownloadQr project={project} planGroups={planGroups} plans={plans} phases={phases} phase={activePhase} types={types} typeVars={typeVars} plots={activePlots} activeQtys={activeQtys} wrapper="ms-2" classes="btn border" />
      </div>
    </div>
  )
}

export default View;
